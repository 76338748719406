@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-section {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.rsd__select-container-month {
    width: 130px;
    margin-right: 40px;
}

.rsd__select-month {
    border: none;
    border-bottom: solid;
}

.rsd__select-container-day {
    width: 75px;
    margin-right: 40px;
}

.rsd__select-day {
    border: none;
    border-bottom: solid;
}

.rsd__select-year {
    border: none;
    border-bottom: solid;
}

.rsd__select-container-year {
    width: 100px;
}

.static-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 1rem;
 }
 
 ul {
   list-style: disc;
   padding-left: 1.5rem;
 }
 
 .ul-lower-alpha {
     counter-reset: list-counter;
     list-style: none;
     padding-left: 1.5rem;
 }
 
 .ul-lower-alpha li {
     counter-increment: list-counter;
     position: relative;
     padding-left: 1.5rem;
 }
 
 .ul-lower-alpha li::before {
     content: "(" counter(list-counter, lower-alpha) ") ";
     position: absolute;
     left: 0;
     font-weight: bold;
 }